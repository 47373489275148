import React, { Component } from 'react';
import LoginPage from '../../modules/auth/pages/LoginPage';
import ForgotPassword from '../../modules/auth/pages/ForgotPassword';
import OTPPage from '../../modules/auth/pages/OTPPage';
import { AppRoute, LPOverridable } from '../../index';
import { Switch } from 'react-router-dom';

export default class PublicLayout extends Component<any, any> {
  match: any;
  constructor(props: any) {
    super(props);
    this.match = props.match;
  }

  componentDidMount() {
    let token = localStorage.getItem('token');
    if (token) {
      // this.props.history.push("/dashboard");
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid login-page">
          <LPOverridable name={'login.page.logo'}>
            <img
              className="login-logo"
              src={require('../../assets/Launchpad_logoWhite.png')}
              alt=""
            />
          </LPOverridable>
          <LPOverridable name={'login.page.text'}>
            <h1>
              Welcome to Launchpad{' '}
              <span style={{ display: 'block', textAlign: 'center' }}>
                Administration
              </span>
            </h1>
          </LPOverridable>
          <div className="login-box">
            <div className="white-blur"></div>
            <div className="inner">
              <Switch>
                <AppRoute path="/forgot-password" component={ForgotPassword} />
                <AppRoute path="/login" exact component={LoginPage} />
                <AppRoute path="/login/otp" component={OTPPage} />
              </Switch>
            </div>
          </div>
        </div>
        <footer className="public-layout-footer">
          <p>©2020 Pannovate. All rights reserved.</p>
          <p>European Patent Application No. 14704638.7</p>
        </footer>
      </div>
    );
  }
}
