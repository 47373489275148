import React from 'react';
import { LaunchPadAdmin } from '@launchpad/admin';
import configuration from './configuration';

require('./configuration/styles/overrides.scss');

function App() {
  return <LaunchPadAdmin configuration={configuration} />;
}

export default App;
