import AppStore from '../logic/store/AppStore';

export function getCurrency() {
  let state = AppStore.getState();
  return state.auth.profile.currency;
}

export function getCurrencySymbol(currency = null) {
  if(!currency) {
    currency = getCurrency();
  }
  let map = {
    'EUR': '€',
    'USD': '$',
    'GBP': '£',
  };

  return map[currency] || currency;
}

export default {
  getCurrency,
  getCurrencySymbol
}
