import React from "react";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastHelper from "../../util/ToastHelper";

export default class AppToastAlert extends React.Component {
  render() {
    return (
      <ToastContainer
        ref={ref => ToastHelper.setAlert(ref)}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        transition={Slide}
      />
    );
  }
}
