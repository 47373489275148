import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { OverrideService } from '../../logic/services';

interface IProps {
  isLoading?: boolean;
  customstyle?: any;
  onClick?: () => void;
}

export class AppButton extends React.Component<IProps> {

  state = {
  }

  _getButtonContent() {
    if (this.props.isLoading) {
      return <CircularProgress className='circle-progress' size={20} />;
    }

    return this.props.children;
  }

  render() {
    return (
      <Button variant="contained" className='custom-btn'  {...this.props}>
        {this._getButtonContent()}
      </Button>
    )
  }
}


// export default AppButton;
export default OverrideService.thisOrOverridenComponent(AppButton, 'ui.button');
