import axios from 'axios';
import Logger from '../../util/Logger';
import store from '../store/AppStore';
import Config  from '../../config';

export default class Api {

  static getBaseUrl() {
    if (Config.env === 'live') {
      return Config.url;
    }
    return Config.url;
  }

  static call(method: string | number, data: any = null, onProgress: any = null) {
    let authToken = store.getState().auth.token || null;

    let requestUrl = this.getBaseUrl() + method;
    let ajaxOptions: any = {
      headers: {

        'Content-Type': 'application/json'
      },
      method: 'POST',
      contentType: 'application/json',
      body: JSON.stringify(data),
    };

    if (authToken !== null) {
      ajaxOptions.headers['Authorization'] = 'Bearer ' + authToken;
    }
    Logger.log('api options', requestUrl, ajaxOptions);
    Logger.log('api request', requestUrl, data);

    return new Promise((resolve, reject) => {
      // const config =  {
      //   onUploadProgress: function(progressEvent: any) {
      //     var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
      //   }
      // }

      axios({
        url: this.getBaseUrl() + method,
        method: 'POST',
        // contentType: 'application/json',
        headers: ajaxOptions['headers'],
        data: JSON.stringify(data),
        onUploadProgress: function (progressEvent: any) {
          if (onProgress) {
            onProgress(progressEvent);
          }
        }
      }).then((response: any) => {
        resolve(response.data);
      }).catch((error: any) => {
        if (error.response) {

          Logger.log('data', error.response);

          if (error.response.status === 403) {
            localStorage.removeItem('AUTH_TOKEN')
            window.location.replace('/login');
            // store.dispatch(logoutTriggerAction());
          }

          if (error.response.status === 200) {
            let code = error.response.data.code;

            if (code === 401 || code === 403) {
              localStorage.removeItem('AUTH_TOKEN')
              window.location.replace('/login');
              // store.dispatch(logoutTriggerAction())
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
        reject(error.response ? error.response.data : error);
      })
    })
  }

  static async guardedDownload(url) {
    let response: any = await this.call('tools/download/trigger', {
      file: url
    });

    if(response.data.url) {
      window.location = response.data.url;
    }
  }
}
