import React, { Component } from 'react';
import { AppLayoutWithCard, FormSubmitWrapper, FormHandler, InputField } from '../../../index';
import { Link, Redirect } from 'react-router-dom';
import ForgotPasswordModel from '../models/ForgotPasswordModel';

export default class LoginPage extends Component<any, any> {
  formHandler: FormHandler;

  componentDidMount() {
    this.setState({
      record: {
        ...this.state.record,
        email: '',
        remember: false
      }
    })
  }
  /**
     * Constructor
     *
     * @param props
     */
  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ForgotPasswordModel({
        email: ' ',
      }), {
      redirect: false
    });
    this.formHandler.on('submitSuccess', (data: { success: any; }) => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          })
        }, 3000)
      }
    })
  }

  onSubmitSuccess(data: { success: any; data: { token: any; account: any; }; }) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
    }
  }
  _getModalMessage(data: { success: any; message: any; }) {
    if (!data.success) {
      return data.message
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/'></Redirect>
    }
    let formHandler = this.formHandler;
    return (
      <div className='public-form-holder'>
        <h4>Reset Password</h4>
        <div className='form-wrapper'>
          <FormSubmitWrapper formHandler={formHandler} buttonClass='gold-button' to="/login" buttonText='RESET PASSWORD' getModalMessage={(data: { success: any; message: any; }) => this._getModalMessage(data)}>
            <div className="login-input-wrapper">
              <InputField
                className={'input-group'}
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.email}
                label='Enter E-mail'
                name="email"
                // placeholder={'Enter E-mail'}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.record.email}
                handler={formHandler} />
            </div>
          </FormSubmitWrapper>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Link to="/login" style={{ color: 'white', textDecoration: 'none', fontWeight: 'lighter', fontSize: 15 }}> Back </Link>
          </div>
        </div>
      </div>
    )
  }
}
