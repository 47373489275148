import overrides from './overrides';
import styles from './styles';
// import routes from './routes';
import reducers from './reducers';

const configuration = {
  modules: {
    // TODO: We should be able to pass configuration here, ie dashboard { something: false }
    // dashboard: true,
    // users: true
  },
  overrides,
  styles,
  // routes,
  reducers
};

export default configuration;
