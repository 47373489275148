import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'user.*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: null,
        permission: 'user.*',
        children: [
          {
            label: 'All Users',
            path: '/users/all-users',
            icon: 'users',
            permission: 'user.view'
          },
          {
            label: 'Roles',
            path: '/users/roles',
            icon: 'user-plus',
            permission: 'user.roles.*'
          },
          {
            label: 'Permissions',
            path: '/users/permissions',
            icon: 'key',
            permission: 'user.permissions.*'
          },
          {
            label: ' User Devices',
            path: '/users/user-devices',
            icon: 'laptop',
            permission: 'user.devices.*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}users`}
        load={() => import('../../modules/users/UsersPage')}
      />
    ];
  }
}
