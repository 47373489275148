import React from 'react';
import { LoadingOverlay } from '../../../../../components';

export default class TotalInstallations extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 300);
  }

  render() {
    const androidData = this.props.android;
    const appleData = this.props.apple;
    return (
      <div className="widget widget-auto-height">
        <div className="dark-blur" />
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-mobile" aria-hidden="true" /> Total
            Installations last 7 days
          </h3>
        </div>
        <div className="widget-inner">
          {this.state.loading ? (
            <LoadingOverlay />
          ) : (
            <div className="widget-new-customers">
              <div>
                <i className="fa fa-mobile" aria-hidden="true" />
                <p className="customers-number">
                  {androidData.Installs.googleInstallsInLastWeek +
                    appleData.InstallsLastWeek}{' '}
                  <span>installations</span>
                </p>
              </div>
              <ul>
                <li>Android</li>
                <li>{androidData.Installs.googleInstallsInLastWeek}</li>
              </ul>
              <ul>
                <li>Apple</li>
                <li>{appleData.InstallsLastWeek}</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
