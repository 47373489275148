import React, { Component } from 'react';
import {
  AppLayoutWithCard,
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput
} from '../../../index';
import { Link, Redirect } from 'react-router-dom';
import LoginModel from '../models/LoginModel';
import { loginTriggerAction } from '../AuthActions';
import { connect } from 'react-redux';
import LoginOTPModel from '../models/LoginOTPModel';

class OTPPage extends Component<any, any> {
  formHandler: FormHandler|null = null;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: Readonly<any>) {
    super(props);

    const { otp } = props.history.location.state;

    if(!otp) {
      props.history.replace('/');
      return;
    }

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginOTPModel({
        code: '',
        hash: otp.hash
      }),
      {
        redirect: false,
        otp
      }
    );
  }

  onSubmitSuccess(data: { success: any; data: { token: any; account: any } }) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 3000);
    }
  }
  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }

    if(data.success && !data.message) {
      return 'Authentication successful!';
    }
  }

  _getMesage() {
    const { otp } = this.state;

    switch(otp.handler) {
      case 'email':
        return 'We sent an email with the verification code to your email address. Please enter it below';
      case 'sms':
        return 'We sent an SMS with the verification code to your phone number. Please enter it below';
    }

    return 'Please enter the code below';
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/"></Redirect>;
    }
    let formHandler = this.formHandler;

    let message = this._getMesage();
    return (
      <div className="public-form-holder">
        <h4>2FA Verification</h4>
        <div className="form-wrapper">
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            to="/"
            buttonText="SUBMIT"
            getModalMessage={(data: { success: any; message: any }) =>
              this._getModalMessage(data)
            }
          >
            <div style={{textAlign: 'center'}}><span>{message}</span></div>
            <div className="login-input-wrapper">
              <InputField
                className={'input-group'}
                materialProps={{
                  fullWidth: true
                }}
                label="Code"
                name="code"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.code}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
          >
            <Link
              to="/login"
              style={{
                color: 'white',
                textDecoration: 'none',
                fontWeight: 'lighter',
                fontSize: 15
              }}
            >
              {' '}
              back to login{' '}
            </Link>
          </div>

          {/* <AppCheckboxInput
            name='remember'
            id='rememberField'
            htmlFor='rememberField'
            labelText='Remember me next time'
            value={this.state.remember}
            handler={this.formHandler}
          /> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    payload: { token: any }; // Initial state
  }) => void
) => {
  return {
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(OTPPage);
