import React from 'react';
import Chart from 'chart.js';
import Api from '../../../../../logic/api/Api';
import { LoadingOverlay } from '../../../../../components';

export default class TotalInstallationsChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      labels: []
    };
  }

  componentDidMount() {
    Chart.defaults.global.defaultFontColor = 'white';
    this.setState(
      {
        loading: false
      },
      () => {
        this._loadData();
      }
    );
  }

  async _loadData() {
    const result = await Api.call('stats/stores/installs/overview');

    if (result.success) {
      this.setState(
        {
          ...result.data,
          loading: false
        },
        () => {
          this.renderOrderDotsChart();
        }
      );
    }
  }

  renderOrderDotsChart() {
    const config = {
      type: 'line',
      data: {
        labels: this.state.labels,
        datasets: [
          {
            label: '# of Installations',
            data: this.state.data,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: 'rgba(54, 162, 235, 0.4)',
            borderWidth: 2,
            fill: false
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },

              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };

    const ctx = document.getElementById('installations-dots').getContext('2d');
    window.myScatter = new Chart(ctx, config);
  }

  render() {
    return (
      <div className="widget">
        <div className="dark-blur" />
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-line-chart" aria-hidden="true" /> Total
            Installations this year
          </h3>
        </div>
        <div className="widget-inner" style={{ padding: '10px' }}>
          {this.state.loading ? (
            <LoadingOverlay />
          ) : (
            <div>
              <canvas
                id="installations-dots"
                style={{ width: '100%', height: '350px' }}
                width="600"
                height="350"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
