import URL from 'url-parse';

let Config = {
  env: process.env.REACT_APP_ENVIRONMENT,
  url: process.env.REACT_APP_URL || ''
};

let env = Config.env || (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'dev' : 'live');

let publicUrl = (new URL(Config.url)).origin;

let common = {
  resetDB: false,
  disablePasscode: false,
  forceIntros: false,
  publicUrl: publicUrl,
  passcodeLength: 6,
  cardRatio: 1.56,
  physicalCardCost: 7.50,
  maxTopUpValue: 250,
  minTopUpValue: 10
};

let configuration = {
  ...common,
  checkout_url: 'https://sandbox.checkout.com/api2/v2/tokens/card',
  checkout_public_key: 'pk_test_24166a6e-18f7-4405-b42a-d74aa75dc05d',
  ...Config
}

const config = {

  env,

  // Preloaded configuration
  ...configuration,

  cards: {
    cardPrice: {
      'GBP': 10
    }
  },
  topUp: {
    min: 10,
    max: 200
  },

  facebook: {},
  twitter: {},
  linkedin: {},

  /**
   * Merge data with current object
   *
   * @param {Object} result
   */
  async merge(result) {
    Object.assign(this, result);
  }
}

export default config;
