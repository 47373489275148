import React, { Component } from "react";
import NotificationUserGroupModel from "../../models/NotificationUserGroupModel";
import TextField from "@material-ui/core/TextField";
import AppSelect from "../../../../components/crud/AppSelect";
import GroupConditions from "../components/GroupConditions";
import SelectSpecificUsers from "../components/SelectSpecificUsers";
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput
} from "@launchpad/admin";

export default class UserGroupForm extends Component {
  constructor(props) {
    super(props);

    let modelData = {
      id: this.props.id,
      name: "",
      description: "",
      isTestGroup: false,
      filterType: "conditions",
      userIds: [],
      filter: {
        createdFrom: "",
        createdTo: "",
        lastActivityFrom: "",
        lastActivityTo: "",
        balanceFrom: "",
        balanceTo: "",
        cardNotOrdered: null,
        cardNotActivated: null,
        lastTransactionFrom: "",
        lastTransactionTo: ""
      }
    };

    if (props.record) {
      modelData = { ...modelData, ...props.record };
    }

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new NotificationUserGroupModel(modelData)
    );
  }

  _getFilterOptions() {
    return [
      {
        id: "conditions",
        name: "Specific conditions"
      },
      {
        id: "specific_users",
        name: "Specific users"
      }
    ];
  }

  _getFilterElement() {
    switch (this.state.record.filterType) {
      case "conditions":
        return (
          <GroupConditions
            record={this.state.record}
            formHandler={this.formHandler}
          />
        );
      case "specific_users":
        return (
          <SelectSpecificUsers
            userIds={this.state.record.userIds}
            onChange={userIds =>
              this.formHandler.handleInputChange("userIds", userIds)
            }
          />
        );
    }
  }

  render() {
    let formHandler = this.formHandler;

    return (
      <div
        className="add-new-group"
        style={{ width: "100%", paddingBottom: "40px" }}
      >
        <FormSubmitWrapper formHandler={formHandler} to="/user-groups">
          <div className="row">
            <div className="col-md-5">
              <h3>Basic Info</h3>
              <hr />
              <InputField
                materialProps={{
                  fullWidth: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                customInput={TextField}
                label="Name"
                type="text"
                name="name"
                value={this.state.record.name}
                handler={formHandler}
              />
              <InputField
                materialProps={{
                  fullWidth: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                customInput={TextField}
                label="Description"
                type="text"
                name="description"
                value={this.state.record.description}
                handler={formHandler}
              />
              <AppCheckboxInput
                name="isTestGroup"
                value={this.state.record.isTestGroup}
                handler={this.formHandler}
                labelText="Is testing group"
              />
            </div>

            <div className="col-md-6 col-md-offset-1 user-group-filters">
              <AppSelect
                textFieldProps={{
                  label: "Filter users",
                  InputLabelProps: {
                    shrink: true
                  }
                }}
                options={this._getFilterOptions()}
                name="filterType"
                value={this.state.record.filterType}
                handler={formHandler}
                placeholder="-filter type-"
              />

              {this._getFilterElement()}
            </div>
          </div>
        </FormSubmitWrapper>
      </div>
    );
  }

  _getOldForm() {
    return (
      <div className="add-user-group-container">
        <div className="user-group-input-wrapper">
          <div>
            <label htmlFor="title">Group Name</label>
            <input type="text" id="title" placeholder="" />
          </div>
          <div>
            <label htmlFor="title">Created From</label>
            <input type="text" id="title" placeholder="" />
          </div>
          <div>
            <label htmlFor="title">Created To</label>
            <input type="text" id="title" placeholder="" />
          </div>
        </div>
        <div className="user-group-input-wrapper">
          <div>
            <label htmlFor="title">Balance From</label>
            <input type="text" id="title" placeholder="" />
          </div>
          <div>
            <label htmlFor="title">Balance To</label>
            <input type="text" id="title" placeholder="" />
          </div>
          <div>
            <label htmlFor="title">Supports School</label>
            <input type="text" id="title" placeholder="" />
          </div>
          <div>
            <label htmlFor="title">Supports Project</label>
            <input type="text" id="title" placeholder="" />
          </div>
          <div></div>
          <button type="submit">Save</button>
        </div>
      </div>
    );
  }
}
