import React from 'react';
import Chart from 'chart.js';
import moment from 'moment';
import { LoadingOverlay } from '../../../../components';

export default class CustomLineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      chartData: this.props.data,
      activeBtn: 'week'
    };
  }

  componentDidMount() {
    Chart.defaults.global.defaultFontColor = 'white';
    setTimeout(() => {
      this.setState(
        {
          loading: false,
          chartData: this.props.data
        },
        () => {
          this.renderOrderDotsChart(this.state.chartData.last7Days ? this.state.chartData.last7Days : this.state.chartData);
        }
      );
    }, 1000);
  }

  changeDataForChart(data, acivateButton) {
    this.setState(
      {
        activeBtn: acivateButton
      },
      () => {
        this.renderOrderDotsChart(data);
      }
    );
  }

  renderOrderDotsChart(data) {
    const { graph } = this.props;
    let renderData = null;
    if (graph === 'install') {
      renderData = data.map(d => d.dailyDeviceInstalls);
    }
    if (graph === 'uninstall') {
      renderData = data.map(d => d.dailyDeviceUninstalls);
    }
    if (graph === 'crashes') {
      renderData = data.map(d => d.dailyDeviceCrashes);
    }

    const config = {
      type: 'line',
      data: {
        labels: data.map(l => moment(l.date).format('DD-MMM-YY')),
        datasets: [
          {
            label: `# of ${this.props.label}`,
            data: renderData,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: 'rgba(54, 162, 235, 0.4)',
            borderWidth: 2,
            fill: false
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)'
              },
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };

    if (window.myScatter instanceof Chart) {
      window.myScatter.destroy();
    }
    const ctx = document.getElementById(this.props.chartId).getContext('2d');
    window.myScatter = new Chart(ctx, config);
  }

  render() {
    const { activeBtn } = this.state;
    return (
      <div className="widget widget-full">
        <div className="dark-blur" />
        <div
          className="widget-inner widget-inner-full"
          style={{ padding: '10px' }}
        >
          {this.state.loading ? (
            <div
              style={{
                minHeight: 670,
                height: '100%'
              }}
            >
              <LoadingOverlay />
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <button
                  type="button"
                  onClick={() =>
                    this.changeDataForChart(
                      this.state.chartData.lastWeek,
                      'week'
                    )
                  }
                  className="cancelBtn"
                  style={{
                    backgroundColor:
                      activeBtn === 'week' ? '#459cb7' : 'transparent',
                    color: 'white'
                  }}
                >
                  Last Week
                </button>
                <button
                  type="button"
                  onClick={() =>
                    this.changeDataForChart(
                      this.state.chartData.lastMonth,
                      'Month'
                    )
                  }
                  className="cancelBtn"
                  style={{
                    backgroundColor:
                      activeBtn === 'Month' ? '#459cb7' : 'transparent',
                    color: 'white'
                  }}
                >
                  Last Month
                </button>
                <button
                  type="button"
                  onClick={() =>
                    this.changeDataForChart(
                      this.state.chartData.last90Days,
                      '90Days'
                    )
                  }
                  className="cancelBtn"
                  style={{
                    backgroundColor:
                      activeBtn === '90Days' ? '#459cb7' : 'transparent',
                    color: 'white'
                  }}
                >
                  Last 90 Days
                </button>
              </div>
              <canvas
                id={this.props.chartId}
                style={{ width: '100%', height: '330px' }}
                width="650"
                height="330"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
