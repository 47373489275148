import _ from 'lodash';
import DashboardModule from '../../modules/dashboard/DashboardModule';
import KycModule from '../../modules/kyc/KycModule';
import UsersModule from '../../modules/users/UsersModule';
import CardsModule from '../../modules/cards/CardsModule';
import FxRatesModule from '../../modules/fx-rates/FxRatesModule';
import TransactionsModule from '../../modules/transactions/TransactionsModule';
import ContentManagementModule from '../../modules/content-managament/ContentManagementModule';
import ReportsModule from '../../modules/reports/ReportsModule';
import LegalModule from '../../modules/legal/LegalModule';
import NotificationsModule from '../../modules/push/NotificationsModule';
import ToolsModule from '../../modules/tools/ToolsModule';
import { OverrideService } from '../services';
import AnalyticsModule from '../../modules/analytics/AnalyticsModule';
import PermissionService from '../services/PermissionService';
import BaseModule from './BaseModule';

export default class ModuleService {
  getAllModules() {
    return [
      new DashboardModule(),
      new KycModule(),
      new UsersModule(),
      new CardsModule(),
      new FxRatesModule(),
      new TransactionsModule(),
      new ContentManagementModule(),
      new AnalyticsModule(),
      new ReportsModule(),
      new LegalModule(),
      new NotificationsModule(),
      new ToolsModule()
    ];
  }

  /**
   * Get modules to display
   *
   * TODO: Enable passing some settings to the modules:
   * when true is passed, module is enabled but when object is passed
   * module is both enabled and configured by the passed object.
   */
  getModules() {
    const allModules = this.getAllModules();
    const enabledModules = OverrideService.getModules();

    let filteredModules: any[] = [];
    if (enabledModules && !_.isEmpty(enabledModules)) {
      allModules.forEach(x => {
        if (enabledModules[x.getName()]) {
          filteredModules.push(x);
        }
      });
    } else {
      filteredModules = allModules;
    }

    // TODO: Filter modules based on permission
    return filteredModules.filter((x: BaseModule) => {
      return PermissionService.hasPermission(x.getPermission());
    });
  }

  /**
   * Get navigation
   */
  getNavigation() {
    let items: any[] = [];

    this.getModules().forEach(x => {
      items = [...items, ...x.getNavigation()];
    });

    return items;
  }

  /**
   * Get routes
   *
   * @param currentUrl string Current rl
   */
  getRoutes(currentUrl = '') {
    const moduleRoutes: any[] = [];

    this.getModules().forEach((x: any) => {
      moduleRoutes.push(x.getRoutes(currentUrl));
    });

    return moduleRoutes;
  }
}
